import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormControlLabel,
	Checkbox,
	Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	textField: {
		marginBottom: 15,
	},
	formControl: {
		marginBottom: 15,
	},
}));

export default function Title(props) {
	const classes = useStyles();

	console.log(`Title rendering ${JSON.stringify(props.option)}`);

	const getTextField = () => {
		switch (props.option.type) {
			case "text":
				return (
					<TextField
						className={classes.textField}
						id="outlined-required"
						label={props.option.label}
						onChange={(event) => {
							props.handleChange(event.target.name, event.target.value);
						}}
						value={props.value}
						name={props.option.name}
						defaultValue={" "}
						variant="outlined"
					/>
				);
			case "number":
				return (
					<TextField
						className={classes.textField}
						id="textfield-number"
						type="number"
						label={props.option.label}
						onChange={(event) => {
							props.handleChange(
								event.target.name,
								parseInt(event.target.value)
							);
						}}
						value={props.value}
						name={props.option.name}
						defaultValue={0}
						variant="outlined"
					/>
				);
			case "select":
				return (
					<FormControl variant="outlined" className={classes.formControl}>
						<InputLabel id="select-id">{props.option.label}</InputLabel>
						<Select
							labelId="select-id"
							id="select"
							value={props.value}
							name={props.option.name}
							onChange={(event) => {
								props.handleChange(event.target.name, event.target.value);
							}}
							label={props.option.label}
						>
							{props.option.values.map((optionText, index) => {
								return <MenuItem value={optionText}>{optionText}</MenuItem>;
							})}
						</Select>
					</FormControl>
				);
			case "check-box":
				return (
					<FormControlLabel
						className={classes.textField}
						control={
							<Checkbox
								label={props.option.label}
								checked={props.value}
								name={props.option.name}
								onChange={(event) => {
									props.handleChange(event.target.name, event.target.checked);
								}}
								color="primary"
							/>
						}
						label={props.option.label}
					/>
				);
			case "time":
				return (
					<TextField
						label={props.option.label}
						type="datetime-local"
						defaultValue="0001-01-01T00:00:00"
						value={
							props.value
								? props.value.substring(0, props.value.length - 1)
								: ""
						}
						className={classes.textField}
						name={props.option.name}
						onChange={(event) => {
							if (event.target.value.length == 16) {
								props.handleChange(
									event.target.name,
									event.target.value + ":00" + "Z"
								);
							} else {
								props.handleChange(event.target.name, event.target.value + "Z");
							}
						}}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				);
			case "button":
				return (
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							props.getRandomCard(1);
						}}
					>
						Get Random Card
					</Button>
				);
			case "buttonOld":
				return (
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							props.getRandomCard(0);
						}}
					>
						Get Random Card
					</Button>
				);
			case "buttonNew":
				return (
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							props.getRandomCard(2);
						}}
					>
						Get Card
					</Button>
				);
		}

		return (
			<p style={{ color: "red" }}>
				Couldn't load field <small>{JSON.stringify(props.option)}</small>
			</p>
		);
	};

	return getTextField();
}

Title.propTypes = {
	children: PropTypes.node,
};
